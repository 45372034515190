import { createMuiTheme } from '@material-ui/core';

import {
  COLOR_ERROR,
  COLOR_PRIMARY,
  COLOR_PRIMARY_DARK,
  COLOR_PRIMARY_LIGHT,
  COLOR_SECONDARY,
  COLOR_SUCCESS
} from 'src/app/constants/color';

export const theme = createMuiTheme({
  overrides: {
    MuiLinearProgress: {
      root: { borderRadius: '4px', height: '8px' }
    }
  },
  palette: {
    error: { main: COLOR_ERROR },
    primary: { dark: COLOR_PRIMARY_DARK, light: COLOR_PRIMARY_LIGHT, main: COLOR_PRIMARY },
    secondary: { main: COLOR_SECONDARY },
    success: { main: COLOR_SUCCESS }
  },
  typography: {
    body1: { fontSize: '1rem' }, // 16px
    body2: { fontSize: '0.875rem' }, // 14px
    button: { fontSize: '1rem', fontWeight: 'bold', textTransform: 'none' }, // 16px
    caption: { fontSize: '0.75rem' }, // 12px
    fontFamily: [
      'Lato',
      '-apple-system',
      'system-ui',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
    h1: { fontSize: '3.375rem', fontWeight: 'bold' }, // 54px
    h2: { fontSize: '2.25rem', fontWeight: 'bold' }, // 36px
    h3: { fontSize: '1.5rem', fontWeight: 'bold' }, // 24px
    h4: { fontSize: '1rem', fontWeight: 'bold' }, // 16px
    subtitle1: { fontSize: '1rem', fontWeight: 'bold' }, // 16px
    subtitle2: { fontSize: '0.875rem', fontWeight: 'bold' } // 14px
  }
});
