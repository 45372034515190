export interface Route {
  path: string;
  requiredAuthority?: string;
}

export const DASHBOARD: Route = { path: '/' };
export const LOGIN: Route = { path: '/login' };
export const ORDER_TRACKING: Route = { path: '/order-tracking/:orderId' };

export const ROLE: Route = { path: '/role', requiredAuthority: 'VIEW_ROLE' };
export const ROLE_ADD: Route = { path: `${ROLE.path}/add`, requiredAuthority: 'CREATE_ROLE' };
export const ROLE_EDIT: Route = { path: `${ROLE.path}/:roleId/edit`, requiredAuthority: 'UPDATE_ROLE' };

export const USER: Route = { path: '/user', requiredAuthority: 'VIEW_USER' };
export const USER_ADD: Route = { path: `${USER.path}/add`, requiredAuthority: 'CREATE_USER' };
export const USER_DETAIL: Route = { path: `${USER.path}/:userId`, requiredAuthority: 'VIEW_USER' };
export const USER_EDIT: Route = { path: `${USER_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_USER' };
export const USER_BRANCH_MAPPING: Route = {
  path: `${USER_DETAIL.path}/cabang-mapping`,
  requiredAuthority: 'VIEW_USER_BRANCH'
};
export const USER_BRANCH_MAPPING_EDIT: Route = {
  path: `${USER_BRANCH_MAPPING.path}/edit`,
  requiredAuthority: 'UPDATE_USER_BRANCH'
};
export const USER_MAPPING: Route = { path: `${USER_DETAIL.path}/mapping` };

export const PROFILE: Route = { path: '/profile', requiredAuthority: 'VIEW_MY_PROFILE' };
export const PROFILE_EDIT: Route = { path: `${PROFILE.path}/edit`, requiredAuthority: 'UPDATE_MY_PROFILE' };

export const BRANCH: Route = { path: `/cabang`, requiredAuthority: 'VIEW_BRANCH' };
export const BRANCH_DETAIL: Route = { path: `${BRANCH.path}/:branchId`, requiredAuthority: 'VIEW_BRANCH' };
export const BRANCH_ADD: Route = { path: `${BRANCH.path}/add`, requiredAuthority: 'CREATE_BRANCH' };
export const BRANCH_EDIT: Route = { path: `${BRANCH_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_BRANCH' };

export const CONTACT: Route = { path: `/contact`, requiredAuthority: 'VIEW_CONTACT' };
export const CONTACT_ADD: Route = { path: `${CONTACT.path}/add`, requiredAuthority: 'CREATE_CONTACT' };
export const CONTACT_EDIT: Route = { path: `${CONTACT.path}/:contactId/edit`, requiredAuthority: 'UPDATE_CONTACT' };

export const ITEM: Route = { path: `/item`, requiredAuthority: 'VIEW_ITEM' };
export const ITEM_DETAIL: Route = { path: `${ITEM.path}/:itemId`, requiredAuthority: 'VIEW_ITEM' };
export const ITEM_ADD: Route = { path: `${ITEM.path}/add`, requiredAuthority: 'CREATE_ITEM' };
export const ITEM_EDIT: Route = { path: `${ITEM_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_ITEM' };

export const BROADCAST: Route = { path: `/broadcast`, requiredAuthority: 'VIEW_BROADCAST' };
export const BROADCAST_DETAIL: Route = { path: `${BROADCAST.path}/:broadcastId`, requiredAuthority: 'VIEW_BROADCAST' };
export const BROADCAST_ADD: Route = { path: `${BROADCAST.path}/add`, requiredAuthority: 'CREATE_BROADCAST' };
export const BROADCAST_EDIT: Route = { path: `${BROADCAST_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_BROADCAST' };

export const CUSTOMER: Route = { path: `/customer`, requiredAuthority: 'VIEW_CUSTOMER' };
export const CUSTOMER_DETAIL: Route = { path: `${CUSTOMER.path}/:customerId`, requiredAuthority: 'VIEW_CUSTOMER' };
export const CUSTOMER_ADD: Route = { path: `${CUSTOMER.path}/add`, requiredAuthority: 'CREATE_CUSTOMER' };
export const CUSTOMER_EDIT: Route = { path: `${CUSTOMER_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_CUSTOMER' };

export const LIVE_ORDER: Route = { path: `/live-order`, requiredAuthority: 'VIEW_LIVE_ORDER' };
export const LIVE_ORDER_DETAIL: Route = {
  path: `${LIVE_ORDER.path}/:branchId`,
  requiredAuthority: 'VIEW_LIVE_ORDER'
};
export const LIVE_ORDER_SWITCH_BRACH: Route = {
  path: `${LIVE_ORDER.path}/:branchId`,
  requiredAuthority: 'SWITCH_BRANCH'
};
export const LIVE_ORDER_SWITCH_DRIVER: Route = {
  path: `${LIVE_ORDER.path}/:branchId`,
  requiredAuthority: 'SWITCH_DRIVER'
};

export const ORDER: Route = { path: `/order`, requiredAuthority: 'VIEW_ORDER' };
export const ORDER_DETAIL: Route = { path: `${ORDER.path}/:orderId`, requiredAuthority: 'VIEW_ORDER' };
export const ORDER_ADD: Route = { path: `${ORDER.path}/add`, requiredAuthority: 'CREATE_ORDER' };
export const ORDER_EDIT: Route = { path: `${ORDER_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_ORDER' };

export const DEPOSIT: Route = { path: `/deposit` };
export const ADMIN_DEPOSIT: Route = { path: DEPOSIT.path, requiredAuthority: 'VIEW_ADMIN_DEPOSIT' };
export const ADMIN_DEPOSIT_UPDATE: Route = { path: DEPOSIT.path, requiredAuthority: 'UPDATE_ADMIN_DEPOSIT' };
export const DRIVER_DEPOSIT: Route = { path: DEPOSIT.path, requiredAuthority: 'VIEW_DRIVER_DEPOSIT' };
export const DRIVER_DEPOSIT_UPDATE: Route = { path: DEPOSIT.path, requiredAuthority: 'UPDATE_DRIVER_DEPOSIT' };

export const DRIVER: Route = { path: `/driver-schedule`, requiredAuthority: 'VIEW_DRIVER' };
export const DRIVER_DETAIL: Route = {
  path: `${DRIVER.path}/:driverId`,
  requiredAuthority: 'VIEW_DRIVER'
};
export const DRIVER_ADD: Route = { path: `${DRIVER.path}/add`, requiredAuthority: 'CREATE_DRIVER' };
export const DRIVER_EDIT: Route = {
  path: `${DRIVER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_DRIVER'
};
export const DRIVER_REACTIVE: Route = {
  path: '',
  requiredAuthority: 'UPDATE_DRIVER_REACTIVE'
};

export const DRIVER_DASHBOARD: Route = { path: `/driver-dashboard`, requiredAuthority: 'IS_DRIVER' };
export const DRIVER_DASHBOARD_FINISH_ORDER: Route = {
  path: `${DRIVER_DASHBOARD.path}/:orderId`,
  requiredAuthority: 'FINISH_ORDER'
};
export const DRIVER_DASHBOARD_ACCEPT_ORDER: Route = {
  path: DRIVER_DASHBOARD.path,
  requiredAuthority: 'ACCEPT_ORDER'
};

export const DRIVER_FEE: Route = { path: `/driver-fee`, requiredAuthority: 'VIEW_DRIVER_FEE' };

export const BROADCAST_TEMPLATE: Route = { path: `/broadcast`, requiredAuthority: 'VIEW_BROADCAST' };
export const BROADCAST_TEMPLATE_DETAIL: Route = {
  path: `${BROADCAST_TEMPLATE.path}/:broadcastTemplateId`,
  requiredAuthority: 'VIEW_BROADCAST'
};
export const BROADCAST_TEMPLATE_ADD: Route = {
  path: `${BROADCAST_TEMPLATE.path}/add`,
  requiredAuthority: 'CREATE_BROADCAST'
};
export const BROADCAST_TEMPLATE_EDIT: Route = {
  path: `${BROADCAST_TEMPLATE_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_BROADCAST'
};

export const ORDER_CONFIGURATION: Route = {
  path: `/order-configuration`,
  requiredAuthority: 'VIEW_ORDER_CONFIGURATION'
};
export const ORDER_CONFIGURATION_EDIT: Route = {
  path: `${ORDER_CONFIGURATION.path}/edit`,
  requiredAuthority: 'UPDATE_ORDER_CONFIGURATION'
};
