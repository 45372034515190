import gql from 'graphql-tag';

export const GET_EXPANDED_NAVIGATION_BARS = gql`
  query GetExpandedNavigationBars {
    expandedNavigationBars @client
  }
`;

export interface FetchExpandedNavigationBarsResult {
  expandedNavigationBars: string[];
}

export const IS_EXPANDED = gql`
  query IsExpanded($id: String!) {
    isExpanded(id: $id) @client
  }
`;

export interface FetchIsNavigationBarExpandedResult {
  isExpanded: boolean;
}

export interface FetchIsNavigationBarExpandedVariables {
  id: string;
}

export const TOGGLE_NAVIGATION_BAR = gql`
  mutation ToggleNavigationBar($id: String!) {
    toggleNavigationBar(id: $id) @client
  }
`;

export interface ToggleExpandNavigationBarVariables {
  id: string;
}
