import { LOCALE_EN, LOCALE_ID } from '@gdp/react-app/lib/components/LocaleProvider';
import { ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import momentLocaliser from 'react-widgets-moment';

import { Routes } from 'src/app/components/Routes';
import { intlMessagesEn } from 'src/app/locale/en';
import { intlMessagesId } from 'src/app/locale/id';
import { theme } from 'src/styles/theme';

momentLocaliser();

const messages = {
  [LOCALE_EN]: intlMessagesEn,
  [LOCALE_ID]: intlMessagesId
};

interface AppProps {
  locale: string;
}

export class App extends React.Component<AppProps> {
  componentDidMount() {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  render() {
    return (
      <IntlProvider messages={messages[this.props.locale]} locale={this.props.locale}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </IntlProvider>
    );
  }
}
