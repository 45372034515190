export const intlMessagesId = {
  'title.project': 'GDP React App',

  'link.dashboard': 'Beranda',
  'link.login': 'Masuk',

  'button.toggle': 'Toggle Layout Loading',

  'text.not.found': '404',
  'text.value': 'Isi: '
};
