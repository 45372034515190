import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HttpStatus, HttpStatusContext } from 'src/app/helpers/http';

@withStyles(require('./NotFound.scss'))
export class NotFound extends React.Component {
  render() {
    return <HttpStatusContext.Consumer>{value => this.renderElement(value)}</HttpStatusContext.Consumer>;
  }

  private renderElement(value: HttpStatus | undefined) {
    if (value) {
      value.setStatusNotFound();
    }

    return (
      <div className="NotFound">
        <div className="NotFound--title">
          <FormattedMessage id="text.notFoundTitle" />
        </div>
        <div className="NotFound--description">
          <FormattedMessage id="text.notFoundDescription" />
        </div>
      </div>
    );
  }
}
