import React from 'react';

export class HttpStatus {
  constructor(private httpStatusCode: number) {
  }

  setStatusNotFound() {
    this.httpStatusCode = 404;
  }

  getStatusCode() {
    return this.httpStatusCode;
  }
}

export const HttpStatusContext = React.createContext<HttpStatus | undefined>(undefined);
