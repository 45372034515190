export const intlMessagesEn = {
  'title.project': 'Oasis',

  'action.accept': 'Accept',
  'action.activate': 'Activate',
  'action.add': 'Add',
  'action.assign': 'Assign',
  'action.available': 'Available',
  'action.complete': 'Complete',
  'action.cancel': 'Cancel',
  'action.close': 'Close',
  'action.contact.import': 'Import Contacts',
  'action.continue': 'Continue',
  'action.copyToClipboard': 'Copy to clipboard',
  'action.deactivate': 'Deactivate',
  'action.decline': 'Decline',
  'action.deliver': 'Deliver',
  'action.detail': 'Detail',
  'action.down': '↓',
  'action.downloadCsv': 'Download CSV',
  'action.downloadInvoice': 'Download Invoice',
  'action.edit': 'Edit',
  'action.edit.profile.self': 'Edit my profile',
  'action.finish': 'Finish',
  'action.generatePdf': 'Generate PDF',
  'action.inTrouble': 'In Trouble',
  'action.login.submit': 'Sign in',
  'action.logout': 'Sign out',
  'action.mapping': 'Mapping',
  'action.maps': 'Maps',
  'action.profile.self': 'My profile',
  'action.report': 'Report',
  'action.reassign': 'Reassign',
  'action.reactivate': 'Reactivate',
  'action.retry': 'Retry',
  'action.select': 'Select',
  'action.soldOut': 'Sold Out',
  'action.start': 'Start',
  'action.transfer': 'Transfer',
  'action.switchBranch': 'Switch Cabang',
  'action.switchDriver': 'Switch Driver',
  'action.switchPayment': 'Switch Payment',
  'action.up': '↑',
  'action.userMapping': 'Mapping',

  'button.toggle': 'Toggle Layout Loading',

  'dayOfWeek.friday': 'Friday',
  'dayOfWeek.monday': 'Monday',
  'dayOfWeek.saturday': 'Saturday',
  'dayOfWeek.sunday': 'Sunday',
  'dayOfWeek.thursday': 'Thursday',
  'dayOfWeek.tuesday': 'Tuesday',
  'dayOfWeek.wednesday': 'Wednesday',

  'form.action.add': 'Add',
  'form.action.assign': 'Assign',
  'form.action.back': 'Back',
  'form.action.cancel': 'Cancel',
  'form.action.decline': 'Decline',
  'form.action.decline.submit': 'Submit Decline',
  'form.action.delete': 'Delete',
  'form.action.deleteAll': 'Delete All',
  'form.action.deleteCurrentPage': 'Delete Current Page',
  'form.action.download': 'Download',
  'form.action.downloadAll': 'Download All',
  'form.action.downloadCsv': 'Download CSV',
  'form.action.edit': 'Edit',
  'form.action.filter': 'Filter',
  'form.action.finish': 'Finish',
  'form.action.next': 'Next',
  'form.action.remove': 'Remove',
  'form.action.reset': 'Reset',
  'form.action.resetAll': 'Reset All',
  'form.action.resetCurrentPage': 'Reset Current Page',
  'form.action.resetPassword': 'Reset Password',
  'form.action.run': 'Run',
  'form.action.save': 'Save',
  'form.action.stop': 'Stop',
  'form.action.stopAll': 'Stop All',
  'form.action.stopCurrentPage': 'Stop Current Page',
  'form.action.updateLatitudeLongitude': 'Update Latitude Longitude',

  'generic.id.label.customerAddress': 'Alamat Pengantaran',
  'generic.id.label.customerAddressDescription': 'Deskripsi Alamat Pengantaran',
  'generic.id.label.customerId': 'No Pelanggan',
  'generic.id.label.customerName': 'Nama Pelanggan',
  'generic.id.label.customerPhoneNo': 'No. Telp Pelanggan',
  'generic.id.label.deliveryStatus': 'Status Pengantaran',
  'generic.id.label.deliveryTime': 'Waktu',
  'generic.id.label.description': 'Catatan',
  'generic.id.label.driverName': 'Nama Driver',
  'generic.id.label.fee': 'Fee',
  'generic.id.label.image': 'Bukti Foto Pengantaran',
  'generic.id.label.itemQuantity': '{quantity} item',
  'generic.id.label.order': 'Pesanan',
  'generic.id.label.orderId': 'TRX ID',
  'generic.id.label.paidAt': 'Waktu Pembayaran',
  'generic.id.label.payment': 'Metode Bayar',
  'generic.id.label.paymentLink': 'Link Bayar',
  'generic.id.label.paymentMethod': 'Metode Bayar',
  'generic.id.label.scheduledAt': 'Tanggal pesanan',
  'generic.id.label.target': 'Target',
  'generic.id.label.totalItem': 'Total Pesanan',
  'generic.id.label.totalPrice': 'Total Harga',

  'generic.label.active': 'Active',
  'generic.label.activeWithQuestionMark': 'Active?',
  'generic.label.additionalPrice': 'Additional Price',
  'generic.label.address': 'Address',
  'generic.label.addressDescription': 'Address Description',
  'generic.label.amount': 'Amount',
  'generic.label.amountType': 'Amount Type',
  'generic.label.apiKey': 'Api Key',
  'generic.label.attachment': 'Attachment',
  'generic.label.attachmentUrl': 'Attachment URL',
  'generic.label.birthdate': 'Birthdate',
  'generic.label.branch': 'Cabang',
  'generic.label.chatbotImage': 'Chatbot Image',
  'generic.label.clientId': 'Client ID',
  'generic.label.close': 'Close',
  'generic.label.channelId': 'Channel ID',
  'generic.label.chatbotConfiguration': 'Chatbot Configuration',
  'generic.label.city': 'City',
  'generic.label.createdAt': 'Created At',
  'generic.label.customerAddress': 'Customer Address',
  'generic.label.customerAddressDescription': 'Customer Address Description',
  'generic.label.customerEmail': 'Customer Email',
  'generic.label.customerDetail': 'Customer Detail',
  'generic.label.customerId': 'Customer Id',
  'generic.label.customerName': 'Customer Name',
  'generic.label.customerPhoneNo': 'Customer Phone Number',
  'generic.label.customerType': 'Customer Type',
  'generic.label.dateAvailability': 'Date Availability',
  'generic.label.defaultBranch': 'Default Branch',
  'generic.label.defaultBranchAddress': 'Default Branch Address',
  'generic.label.defaultBranchName': 'Default Branch Name',
  'generic.label.deliveryDetail': 'Delivery Detail',
  'generic.label.deliveryDuration': 'Delivery Duration',
  'generic.label.deliveryFinishTime': 'Delivery Finish Time',
  'generic.label.deliveryStartTime': 'Delivery Start Time',
  'generic.label.deliveryStatus': 'Delivery Status',
  'generic.label.description': 'Description',
  'generic.label.isDisplayedAdminOnly': 'Displayed Admin Only',
  'generic.label.draft': 'Save as draft',
  'generic.label.driver': 'Driver',
  'generic.label.driverFee': 'Driver Fee',
  'generic.label.driverName': 'Driver Name',
  'generic.label.duration': 'Duration',
  'generic.label.email': 'Email',
  'generic.label.endDate': 'End Date',
  'generic.label.endHour': 'End Hour',
  'generic.label.gender': 'Gender',
  'generic.label.hot': 'Hot',
  'generic.label.hotTemperature': 'Hot Temperature',
  'generic.label.ice': 'Ice',
  'generic.label.iceTemperature': 'Ice Temperature',
  'generic.label.id': 'ID',
  'generic.label.image': 'Image',
  'generic.label.imageFile': 'Image File',
  'generic.label.imageUrl': 'Image URL',
  'generic.label.infoRumahOasis': 'Info Rumah Oasis',
  'generic.label.initialName': 'Initial Name',
  'generic.label.item': 'Item',
  'generic.label.itemQuantity': '{quantity} item',
  'generic.label.marketplaceInvoiceCode': 'Invoice Code - Marketplace',
  'generic.label.latitude': 'Latitude',
  'generic.label.longitude': 'Longitude',
  'generic.label.merchantId': 'Merchant ID',
  'generic.label.message': 'Message',
  'generic.label.mid': 'MID',
  'generic.label.missedAt': 'Missed At',
  'generic.label.name': 'Name',
  'generic.label.none': 'None',
  'generic.label.notes': 'Notes',
  'generic.label.notSet': '-',
  'generic.label.oldPassword': 'Old Password',
  'generic.label.open': 'Open',
  'generic.label.order': 'Order',
  'generic.label.orderId': 'Order ID',
  'generic.label.orderDetail': 'Order Detail',
  'generic.label.orderDuration': 'Order Duration',
  'generic.label.paidAt': 'Paid At',
  'generic.label.payment': 'Payment',
  'generic.label.paymentLink': 'Payment Link',
  'generic.label.paymentMethod': 'Payment Method',
  'generic.label.paymentProvider': 'Payment Method',
  'generic.label.paymentStatus': 'Payment Status',
  'generic.label.phoneNo': 'Phone Number',
  'generic.label.physicalQuantity': 'Physical Quantity',
  'generic.label.postalCode': 'Postal Code',
  'generic.label.price': 'Price',
  'generic.label.priority': 'Priority',
  'generic.label.products': 'Products',
  'generic.label.productCode': 'Product Code',
  'generic.label.productId': 'Product ID',
  'generic.label.productName': 'Product Name',
  'generic.label.recipients': 'Recipients',
  'generic.label.recurring': 'Is Recurring',
  'generic.label.rewardRule': 'Reward Rule',
  'generic.label.scheduledAt': 'Scheduled At',
  'generic.label.scheduledTime': 'Scheduled Time',
  'generic.label.schedules': 'Schedules',
  'generic.label.searchCustomer': 'Search Customer',
  'generic.label.sentAt': 'Sent At',
  'generic.label.storeExtId': 'Store Ext ID',
  'generic.label.startDate': 'Start Date',
  'generic.label.startHour': 'Start Hour',
  'generic.label.status': 'Status',
  'generic.label.sugar': 'Sugar',
  'generic.label.target': 'Target',
  'generic.label.temperature': 'Temperature',
  'generic.label.templateId': 'Template Id',
  'generic.label.tid': 'Terminal ID',
  'generic.label.to': 'To',
  'generic.label.totalItem': 'Total Item',
  'generic.label.totalPrice': 'Grand Total',
  'generic.label.unit': 'Unit',
  'generic.label.usage': ' Usage',
  'generic.label.usageLimit': 'Limit',
  'generic.label.usageType': 'Usage Type',
  'generic.label.variantName': 'Variant Name',
  'generic.label.variantUnitName': 'Variant Unit Name',

  'label.branch.operationals': 'Cabang Operationals',
  'label.breakOrOffHourDriverDashboard.completed': 'Pengiriman sudah selesai semua hari ini',
  'label.breakOrOffHourDriverDashboard.break': 'sedang istirahat',
  'label.breakOrOffHourDriverDashboard.noOrder': 'Anda tidak memiliki permintaan pesanan karena saat ini',
  'label.breakOrOffHourDriverDashboard.offHour': 'berada di luar jadwal kerja Anda',
  'label.delivery.branch.option': 'Choose Cabang',
  'label.deposit.adminTextInputLabel': 'Confirm Total Cash You Transfer',
  'label.deposit.adminTitle': 'Transfer Cash',
  'label.deposit.adminTotalDepositedAmountLabel': 'Cash to be Transferred',
  'label.deposit.driverTextInputLabel': 'Input Total Cash You Accepted',
  'label.deposit.driverTitle': 'Accept Cash',
  'label.deposit.driverTotalDepositedAmountLabel': 'Cash to be Deposited',
  'label.driver.operational.branch': 'Cabang',
  'label.driver.operationals': 'Driver Operationals',
  'label.driverFee.showResult': 'Shows result from ',
  'label.findAndUpdateCustomerMapCoordinateButton.findAndUpdateAddressCoordinate': 'Find and Update Address Coordinate',
  'label.findAndUpdateCustomerMapCoordinateButton.errorFindAndUpdateAddressCoordinate':
    'Failed to find and update address coordinate',
  'label.getMapCoordinateByAddressButton.findAddressCoordinate': 'Find Address Coordinate',
  'label.getMapCoordinateByAddressButton.errorFindAddressCoordinate': 'Failed to find address coordinate',
  'label.getNearestBranchByMapCoordinateButton.findNearestBranch': 'Find Nearest Branch',
  'label.getNearestBranchByMapCoordinateButton.errorFindNearestBranch': 'Failed to find nearest branch',
  'label.inactiveDriverDashboard.accountInfo': 'Akun Anda telah dinonaktifkan',
  'label.inactiveDriverDashboard.toDoInactive': 'silakan menghubungi administrator',
  'label.inactiveDriverDashboard.toDoAccountInfo': 'Untuk memulihkan akun Anda',
  'label.inactiveDriverDashboard.toDoSuspended': 'setorkan semua pembayaran COD yang telah anda terima ke Admin Cabang',
  'label.login.password': 'Password',
  'label.login.username': 'Username',
  'label.order.actualScheduledAt': 'Order Time',
  'label.order.scheduledAt': 'Order Scheduled Time',
  'label.role.active': 'Active',
  'label.role.authorities': 'Authorities',
  'label.role.description': 'Description',
  'label.role.name': 'Name',
  'label.user.confirmPassword': 'Confirm Password',
  'label.user.email': 'Email',
  'label.user.id': 'ID',
  'label.user.manager': 'Manager',
  'label.user.name': 'Name',
  'label.user.password': 'Password',
  'label.user.phoneNo': 'Phone Number',
  'label.user.role': 'Role',
  'label.user.username': 'Username',

  'link.branch': 'Cabang',
  'link.branch.add': 'Add Cabang',
  'link.branch.detail': 'Cabang Detail',
  'link.branch.edit': 'Edit Cabang',
  'link.broadcast': 'Broadcast Message',
  'link.broadcast.add': 'Add Broadcast Message',
  'link.broadcast.detail': 'Broadcast Message Detail',
  'link.broadcast.edit': 'Edit Broadcast Message',
  'link.broadcastTemplate': 'Broadcast Template',
  'link.broadcastTemplate.add': 'Add Broadcast Template',
  'link.broadcastTemplate.edit': 'Edit Broadcast Template',
  'link.broadcastTemplate.detail': 'Broadcast Template Detail',
  'link.contact': 'Contacts',
  'link.contact.add': 'Add Contact',
  'link.contact.edit': 'Edit Contact',
  'link.customer': 'Customer',
  'link.customer.add': 'Add Customer',
  'link.customer.detail': 'Customer Detail',
  'link.customer.edit': 'Edit Customer',
  'link.dashboard': 'Dashboard',
  'link.driver': 'Driver',
  'link.driverDashboard': 'Driver Dashboard',
  'link.driverDashboard.finish': 'Finish Order',
  'link.driverFee': 'Driver Fee',
  'link.driver.add': 'Add Driver',
  'link.driver.detail': 'Driver Detail',
  'link.driver.edit': 'Edit Driver',
  'link.item': 'Item',
  'link.item.add': 'Add Item',
  'link.item.detail': 'Item Detail',
  'link.item.edit': 'Edit Item',
  'link.liveOrder': 'Live Order',
  'link.liveOrder.detail': 'Live Order',
  'link.liveOrder.detail.title': 'Live Order - {branchName}',
  'link.order': 'Order',
  'link.orderConfiguration': 'Order Configuration',
  'link.orderConfiguration.edit': 'Edit Order Configuration',
  'link.orderLog': 'Order Log',
  'link.orderSummary': 'Order Summary',
  'link.order.add': 'Add Order',
  'link.order.edit': 'Edit Order',
  'link.order.detail': 'Order Detail',
  'link.deposit': 'Payment COD Order',
  'link.profile': 'My Profile',
  'link.profileEdit': 'Edit My Profile',
  'link.role': 'Role',
  'link.roleAdd': 'Add Role',
  'link.roleEdit': 'Edit Role',
  'link.user': 'User',
  'link.user.branchMapping': 'Cabang Mapping',
  'link.user.branchMappingEdit': 'Edit Cabang Mapping',
  'link.user.detail': 'User Detail',
  'link.user.mapping': 'User Mapping',
  'link.userActivity': 'User Activity',
  'link.userAdd': 'Add User',
  'link.userDetail': 'Detail User',
  'link.userEdit': 'Edit User',

  'modal.confirmation.body': 'Are you sure you want to do this?',
  'modal.confirmation.cancel': 'Cancel',
  'modal.confirmation.title': 'Confirmation',
  'modal.confirmation.yes': 'Yes',
  'modal.close': 'Close',

  'placeholder.blank': ' ',
  'placeholder.branch.address': 'Cabang Address',
  'placeholder.branch.city': 'Cabang City',
  'placeholder.branch.name': 'Cabang Name',
  'placeholder.branch.latitude': '-6.200000',
  'placeholder.branch.longitude': '106.816666',
  'placeholder.branch.postalCode': '123456',
  'placeholder.broadcast.name': 'Broadcast Name',
  'placeholder.broadcast.template.name': 'Broadcast Name',
  'placeholder.contact.name': 'Contact Name',
  'placeholder.contact.phoneNo': '081234567890',
  'placeholder.customer.address': 'Customer Address',
  'placeholder.customer.addressDescription': 'Customer Address Description',
  'placeholder.customer.email': 'oasis@oasis.com',
  'placeholder.customer.name': 'Customer Name',
  'placeholder.customer.latitude': '-6.200000',
  'placeholder.customer.longitude': '106.816666',
  'placeholder.customer.phoneNo': '081234567890',
  'placeholder.date': 'DD-MM-YYYY',
  'placeholder.datetime': 'DD-MM-YYYY HH:mm',
  'placeholder.empty': '-',
  'placeholder.input.dateTime.default': 'DD-MM-YYYY HH:mm',
  'placeholder.item.name': 'Item Name',
  'placeholder.item.price': '20.000',
  'placeholder.item.physicalQuantity': '1',
  'placeholder.login.password': 'Type in your password',
  'placeholder.login.username': 'Type in your username',
  'placeholder.optional': '(Optional)',
  'placeholder.role.description': 'Role’s description',
  'placeholder.role.name': 'Role’s name',
  'placeholder.time': 'HH:mm',
  'placeholder.user.confirmPassword': 'Re-type the password',
  'placeholder.user.email': 'example@mail.com',
  'placeholder.user.id': 'ID',
  'placeholder.user.latitude': 'Latitude',
  'placeholder.user.longitude': 'Longitude',
  'placeholder.user.name': 'User’s full name',
  'placeholder.user.password': 'Type in the password',
  'placeholder.user.phoneNo': 'User’s phone number',
  'placeholder.user.role': 'Role',
  'placeholder.user.username': 'Use letters and numbers',

  'sidebar.branch': 'Cabang',
  'sidebar.broadcast': 'Broadcast Message',
  'sidebar.broadcastContact': 'Broadcast',
  'sidebar.contact': 'Contacts',
  'sidebar.customer': 'Customer',
  'sidebar.dashboard': 'Dashboard',
  'sidebar.driver': 'Driver',
  'sidebar.driverDashboard': 'Driver Dashboard',
  'sidebar.driverFee': 'Driver Fee',
  'sidebar.driverSchedule': 'Driver Schedule',
  'sidebar.general': 'General',
  'sidebar.item': 'Item',
  'sidebar.liveOrder': 'Live Order',
  'sidebar.myProfile': 'My Profile',
  'sidebar.order': 'Order',
  'sidebar.orderConfiguration': 'Order Configuration',
  'sidebar.orderLiveOrder': 'Order',
  'sidebar.deposit': 'Payment COD Order',
  'sidebar.role': 'Role',
  'sidebar.user': 'User',
  'sidebar.userActivity': 'User Activity',
  'sidebar.userRole': 'User & Role',

  'text.not.found': '404',
  'text.notFoundTitle': '404',
  'text.notFoundDescription': 'Page Not Found',
  'text.orderOnProcessTitle': 'Your Order is Being Prepared',
  'text.orderOnProcessDescription': 'Please Wait for a Moment, We Are Processing Your Order.',
  'text.value': 'Value: ',

  'validation.alphanumeric': 'Must be a combination of letters and numbers.',
  'validation.alphanumericOnly': 'Special characters are not allowed.',
  'validation.buildingAndCompanyNameRequired': 'Either Building or Company Name must be filled',
  'validation.capitalAlphanumericOnly': 'Only capital letters and numbers are allowed.',
  'validation.depositAmountError': "Deposited amount isn't allowed",
  'validation.eightCharMinimum': 'Minimum 8 characters.',
  'validation.email': 'Invalid email format.',
  'validation.greaterThanOne': 'Must be greater than 1',
  'validation.greaterThanZero': 'Must be greater than 0',
  'validation.insufficientStock': 'Insufficient stock',
  'validation.invalidNumber': 'Invalid number',
  'validation.invalidStartDateOrEndDate': 'Invalid start date or end date',
  'validation.latitude': 'Invalid latitude',
  'validation.length.4': 'Must 4 characters.',
  'validation.longitude': 'Invalid longitude',
  'validation.maxLength.2': 'Maximum length 2 digits',
  'validation.maxLength.12': 'Maximum length 12 digits',
  'validation.maxLength.15': 'Maximum length 15 digits',
  'validation.maxLength.32': 'Maximum length 32 characters',
  'validation.maxNumber': 'Maximum value {number}',
  'validation.maxPeriodSmaller': 'The end period should be greater than the start period',
  'validation.maximumDurationExceeded': 'Maximum duration is 31 days',
  'validation.minMaxCup': 'Minimum cup must lower than Maximum cup',
  'validation.lowMediumHigh': 'Medium must between low and high',
  'validation.minPeriod': 'The start period should be greather than now',
  'validation.minPeriodGreater': 'The start period should be smaller than the end period',
  'validation.nonNegative': 'Should not be negative',
  'validation.notEmptyArray': 'Should not be empty',
  'validation.numericOnly': 'Value of this field must be numeric.',
  'validation.passwordNotMatch': 'Password does not match.',
  'validation.phone': 'Invalid phone format.',
  'validation.product.cookingDuration': 'It must be greater than mininum cooking duration.',
  'validation.required': 'This field is required.',
  'validation.time.start.end': 'Open must less tha close',
  'validation.twoChar': 'Must be 2 characters',
  'validation.username': 'Invalid username format.'
};
