import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { AppState } from 'src/app/models/app';
import { layoutReducer } from 'src/app/reducers/layout';

export const appReducer: Reducer<AppState> = combineReducers<AppState>({
  form: formReducer,
  layoutState: layoutReducer
});
