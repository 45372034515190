import { Resolvers } from 'apollo-client';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { GET_EXPANDED_NAVIGATION_BARS } from './navigationbar';

export const defaultOptions: DefaultOptions = {
  mutate: {
    errorPolicy: 'all'
  },
  query: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first'
  },
  watchQuery: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  }
};

export const resolvers: Resolvers = {
  Mutation: {
    toggleNavigationBar: (_, { id }, { cache }) => {
      let { expandedNavigationBars } = cache.readQuery({ query: GET_EXPANDED_NAVIGATION_BARS });
      const data = {
        expandedNavigationBars: expandedNavigationBars.includes(id)
          ? expandedNavigationBars.filter((e: string) => e !== id)
          : [...expandedNavigationBars, id]
      };
      cache.writeQuery({ query: GET_EXPANDED_NAVIGATION_BARS, data });
      return data.expandedNavigationBars;
    }
  },
  Query: {
    isExpanded: (_, args, { cache }) => {
      const { expandedNavigationBars } = cache.readQuery({ query: GET_EXPANDED_NAVIGATION_BARS });
      return expandedNavigationBars.includes(String(args.id));
    }
  }
};

export interface ApolloState {
  data: {
    expandedNavigationBars: string[]
  };
}

export const initialData: ApolloState = {
  data: {
    expandedNavigationBars: []
  }
};
